export const actionTypes = {
  CHANGE_SCREEN_TYPE: 'CHANGE_SCREEN_TYPE',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  SET_USER_AGENT: 'SET_USER_AGENT',
  SHOW_CHAT_FAQ_MODAL: 'SET_USER_AGENT',
  CHANGE_SCREEN_STATE: 'CHANGE_SCREEN_STATE',
  SHOW_CHAT: 'SHOW_CHAT',
};

export const changeScreenType = (screenType) => ({
  type: actionTypes.CHANGE_SCREEN_TYPE,
  screenType,
});

export const changeShowModal = () => ({
  type: actionTypes.SHOW_MODAL,
});

export const changeHideModal = () => ({
  type: actionTypes.HIDE_MODAL,
});

export const setUserAgent = (userAgent) => ({
  type: actionTypes.SET_USER_AGENT,
  userAgent,
});

export const showChatFaqModal = (data) => ({
  type: actionTypes.SHOW_CHAT_FAQ_MODAL,
  data,
});

export const showChat = (data) => ({
  type: actionTypes.SHOW_CHAT,
  data,
});

export const changeScreenState = (screenState) => ({
  type: actionTypes.CHANGE_SCREEN_STATE,
  screenState,
});
