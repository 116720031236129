export const actionTypes = {
  CHANGE_RECOVERY_STATE: 'CHANGE_RECOVERY_STATE',

  RESET_RECOVERY_DATA: 'RESET_RECOVERY_DATA',
  ACTION_CLEAR_SERVICE: 'ACTION_CLEAR_SERVICE',

  SEND_RECOVERY_CODE: 'SEND_RECOVERY_CODE',
  SEND_RECOVERY_CODE_REQUEST: 'SEND_RECOVERY_CODE_REQUEST',
  SEND_RECOVERY_CODE_SUCCESS: 'SEND_RECOVERY_CODE_SUCCESS',
  SEND_RECOVERY_CODE_ERROR: 'SEND_RECOVERY_CODE_ERROR',

  SEND_RECOVERY_APPROVE_CODE: 'SEND_RECOVERY_APPROVE_CODE',
  SEND_RECOVERY_APPROVE_CODE_REQUEST: 'SEND_RECOVERY_APPROVE_CODE_REQUEST',
  SEND_RECOVERY_APPROVE_CODE_SUCCESS: 'SEND_RECOVERY_APPROVE_CODE_SUCCESS',
  SEND_RECOVERY_APPROVE_CODE_ERROR: 'SEND_RECOVERY_APPROVE_CODE_ERROR',

  SEND_RECOVERY_NEW_PASSWORD: 'SEND_RECOVERY_NEW_PASSWORD',
  SEND_RECOVERY_NEW_PASSWORD_REQUEST: 'SEND_RECOVERY_NEW_PASSWORD_REQUEST',
  SEND_RECOVERY_NEW_PASSWORD_SUCCESS: 'SEND_RECOVERY_NEW_PASSWORD_SUCCESS',
  SEND_RECOVERY_NEW_PASSWORD_ERROR: 'SEND_RECOVERY_NEW_PASSWORD_ERROR',

  ACTION_APPROVE_PASSPORT_SEND: 'ACTION_APPROVE_PASSPORT_SEND',
  ACTION_APPROVE_PASSPORT_REQUEST: 'ACTION_APPROVE_PASSPORT_REQUEST',
  ACTION_APPROVE_PASSPORT_SUCCESS: 'ACTION_APPROVE_PASSPORT_SUCCESS',
  ACTION_APPROVE_PASSPORT_ERROR: 'ACTION_APPROVE_PASSPORT_ERROR',

  ACTION_SEND_REGISTRATION_CODE: 'ACTION_SEND_REGISTRATION_CODE',
  ACTION_SEND_REGISTRATION_CODE_REQUEST: 'ACTION_SEND_REGISTRATION_CODE_REQUEST',
  ACTION_SEND_REGISTRATION_CODE_SUCCESS: 'ACTION_SEND_REGISTRATION_CODE_SUCCESS',
  ACTION_SEND_REGISTRATION_CODE_ERROR: 'ACTION_SEND_REGISTRATION_CODE_ERROR',

  ACTION_APPROVE_CODE_REGISTRATION: 'ACTION_APPROVE_CODE_REGISTRATION',
  ACTION_APPROVE_CODE_REGISTRATION_REQUEST: 'ACTION_APPROVE_CODE_REGISTRATION_REQUEST',
  ACTION_APPROVE_CODE_REGISTRATION_SUCCESS: 'ACTION_APPROVE_CODE_REGISTRATION_SUCCESS',
  ACTION_APPROVE_CODE_REGISTRATION_ERROR: 'ACTION_APPROVE_CODE_REGISTRATION_ERROR',

  ACTION_REGISTRATION_NEW_PASSWORD: 'ACTION_REGISTRATION_NEW_PASSWORD',
  ACTION_REGISTRATION_NEW_PASSWORD_REQUEST: 'ACTION_REGISTRATION_NEW_PASSWORD_REQUEST',
  ACTION_REGISTRATION_NEW_PASSWORD_SUCCESS: 'ACTION_REGISTRATION_NEW_PASSWORD_SUCCESS',
  ACTION_REGISTRATION_NEW_PASSWORD_ERROR: 'ACTION_REGISTRATION_NEW_PASSWORD_ERROR',
};

export const changeRecoveryState = screenState => ({
  type: actionTypes.CHANGE_RECOVERY_STATE,
  screenState,
});

export const resetRecovery = () => ({ type: actionTypes.RESET_RECOVERY_DATA });

export const sendCode = phone => ({ type: actionTypes.SEND_RECOVERY_CODE, phone });

export const sendCodeRequest = phone => ({ type: actionTypes.SEND_RECOVERY_CODE_REQUEST, phone });

export const sendCodeSuccess = data => ({ type: actionTypes.SEND_RECOVERY_CODE_SUCCESS, data });

export const sendCodeError = error => ({ type: actionTypes.SEND_RECOVERY_CODE_ERROR, error });


export const sendApproveCode = dataRequest => ({
  type: actionTypes.SEND_RECOVERY_APPROVE_CODE,
  dataRequest,
});

export const sendApproveCodeRequest = () => ({
  type: actionTypes.SEND_RECOVERY_APPROVE_CODE_REQUEST,
});

export const sendApproveCodeSuccess = data => ({
  type: actionTypes.SEND_RECOVERY_APPROVE_CODE_SUCCESS,
  data,
});

export const sendApproveCodeError = error => ({
  type: actionTypes.SEND_RECOVERY_APPROVE_CODE_ERROR,
  error,
});


export const sendNewPassword = dataRequest => ({
  type: actionTypes.SEND_RECOVERY_NEW_PASSWORD,
  dataRequest,
});

export const sendNewPasswordRequest = () => ({
  type: actionTypes.SEND_RECOVERY_NEW_PASSWORD_REQUEST,
});

export const sendNewPasswordSuccess = () => ({
  type: actionTypes.SEND_RECOVERY_NEW_PASSWORD_SUCCESS,
});

export const sendNewPasswordError = error => ({
  type: actionTypes.SEND_RECOVERY_NEW_PASSWORD_ERROR,
  error,
});


export const actionClearService = () => ({
  type: actionTypes.ACTION_CLEAR_SERVICE,
});

export const registrationSend = (data) => ({
  type: actionTypes.ACTION_APPROVE_PASSPORT_SEND,
  data,
});

export const registrationRequest = () => ({
  type: actionTypes.ACTION_APPROVE_PASSPORT_REQUEST,
});

export const registrationSuccess = (data) => ({
  type: actionTypes.ACTION_APPROVE_PASSPORT_SUCCESS,
  data,
});

export const registrationError = (error) => ({
  type: actionTypes.ACTION_APPROVE_PASSPORT_ERROR,
  error,
});

export const sendRegistrationCode = (phone) => ({ type: actionTypes.ACTION_SEND_REGISTRATION_CODE, phone });

export const sendRegistrationCodeRequest = (phone) => ({ type: actionTypes.ACTION_SEND_REGISTRATION_CODE_REQUEST, phone });

export const sendRegistrationCodeSuccess = (data) => ({ type: actionTypes.ACTION_SEND_REGISTRATION_CODE_SUCCESS, data });

export const sendRegistrationCodeError = (error) => ({ type: actionTypes.ACTION_SEND_REGISTRATION_CODE_ERROR, error });


export const sendApproveCodeRegistration = (data) => ({
  type: actionTypes.ACTION_APPROVE_CODE_REGISTRATION,
  data,
});

export const sendApproveCodeRegistrationRequest = () => ({
  type: actionTypes.ACTION_APPROVE_CODE_REGISTRATION_REQUEST,
});

export const sendApproveCodeRegistrationSuccess = (data) => ({
  type: actionTypes.ACTION_APPROVE_CODE_REGISTRATION_SUCCESS,
  data,
});

export const sendApproveCodeRegistrationError = (error) => ({
  type: actionTypes.ACTION_APPROVE_CODE_REGISTRATION_ERROR,
  error,
});

export const sendRegistrationNewPassword = (data) => ({
  type: actionTypes.ACTION_REGISTRATION_NEW_PASSWORD,
  data,
});

export const sendRegistrationNewPasswordRequest = () => ({
  type: actionTypes.ACTION_REGISTRATION_NEW_PASSWORD_REQUEST,
});

export const sendRegistrationNewPasswordSuccess = () => ({
  type: actionTypes.ACTION_REGISTRATION_NEW_PASSWORD_SUCCESS,
});

export const sendRegistrationNewPasswordError = (error) => ({
  type: actionTypes.ACTION_REGISTRATION_NEW_PASSWORD_ERROR,
  error,
});

